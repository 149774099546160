import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../../../styling/theme'
import Button from '../../Button/Button'

const DeclineMessage = ({ isFirstStep, handleBack, closeModal }) => {
  return (
    <Container>
      <h4>Sorry, it seems as if you're not eligible</h4>
      {isFirstStep ? (
        <p>
          Unfortunately, being registered for Corporation Tax in the UK is a key
          requirement for R&D Tax Relief.
        </p>
      ) : (
        <p>
          Unfortunately your business doesn’t meet the criteria to make an R&D
          tax Credits Claim.
        </p>
      )}
      <p>
        However we may still be able to help you so if you would like to speak
        to one of our R&D experts then please call us on{' '}
        <a href="tel:033 33 444 026">033 33 444 026</a>.
      </p>
      <BtnContainer>
        <Button onClick={handleBack} secondary>
          Back
        </Button>
        <Button onClick={closeModal}>Done</Button>
      </BtnContainer>
    </Container>
  )
}

export default DeclineMessage

const BtnContainer = styled.div`
  margin-top: 40%;
  display: flex;
  & a {
    width: 200px;

    &:first-of-type {
      margin-right: 9px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    flex-direction: column;
    & a {
      width: 100%;
      &:first-of-type {
        margin-right: 0px;
        margin-bottom: 9px;
      }
    }
  }
`

export const Container = styled.div`
  & > h4 {
    color: ${theme.brand.blue};
    font-family: 'Michroma';
    font-weight: bold;
    max-width: 390px;
    margin-bottom: 32px;
  }

  & > p {
    color: #000;
    font-size: 18px;
    margin-bottom: 17px;

    & > a {
      color: ${theme.brand.blue};
      font-weight: bold;
    }
  }
`
