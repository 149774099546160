import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import SectionHeading from '../shared/SectionHeading'
import SmallGrid from '../styling/SmallGrid'
import Button from '../shared/Button/Button'
import EligibityModal from '../shared/Modal/EligibityModal/EligibityModal'

const data = {
  heading: 'Check how R&D tax credits would work for you',
  body:
    'Answer a few quick and simple questions to see where your company is eligible for tax credit.',
}
const PromotionBanner = () => {
  const [showEligibityModal, toggleEligibityModal] = useState(false)
  return (
    <Background>
      <EligibityModal
        showModal={showEligibityModal}
        toggleModal={() => toggleEligibityModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <SmallGrid>
        <Container>
          {data.heading && (
            <SectionHeading color="white" centerMobile>
              {data.heading}
            </SectionHeading>
          )}
          <Col>
            {data.body && <h6>{data.body}</h6>}
            <ButtonContainer>
              <Button onClick={() => toggleEligibityModal(true)}>
                Get started{' '}
              </Button>
            </ButtonContainer>
          </Col>
        </Container>
      </SmallGrid>
    </Background>
  )
}

export default PromotionBanner

const Background = styled.section`
  background: ${theme.text.main};
  padding: 60px 0;
  color: white;
  @media (min-width: 1024px) {
    padding: 13rem 0 16rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const Col = styled.div`
  text-align: center;
  & h6 {
    font-weight: 300;
    margin-bottom: 40px;
    color: white;
    @media (min-width: 768px) {
      margin-bottom: 28px;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    margin-left: 3rem;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    max-width: 262px;
  }
`
