import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import RadioBtn from '../../Form/RadioButton'
import theme from '../../../styling/theme'

interface StepProps {
  setAnswer: (e) => void
  answer: string
}

const Step2: FunctionComponent<StepProps> = ({ setAnswer, answer }) => {
  return (
    <Container>
      <p className="section-tag">Step 2 of 4</p>
      <Question>Do the following apply to your business?</Question>
      <List>
        <li>
          Are you spending money on developing new processes, products or
          services?
        </li>
        <li>Were those projects started in the last 2 or 3 years?</li>
        <li>
          Do you employ staff across a variety of roles who work on projects
          that advance knowledge, and clarify uncertainty?
        </li>
      </List>
      <BtnsContainer>
        <RadioBtn
          onClick={() => {
            setAnswer('Yes')
          }}
          isActive={answer === 'Yes'}
        >
          Yes
        </RadioBtn>
        <RadioBtn
          nomarginright
          onClick={() => {
            setAnswer('No')
          }}
          isActive={answer === 'No'}
        >
          No
        </RadioBtn>
      </BtnsContainer>
    </Container>
  )
}

export default Step2

const Container = styled.div`
  & .section-tag {
    margin-bottom: 10px;
  }
`

const Question = styled.p`
  color: ${theme.text.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  @media (min-width: 768px) {
    max-width: 375px;
  }
  @media (min-width: 104px) {
    margin-top: 5rem;
  }
`
const List = styled.ul`
  margin-top: 30px;
  & li {
    color: ${theme.text.greyLight};
    font-weight: 300;
    margin-bottom: 18px;
  }
  & li::before {
    margin-top: 4px;
  }
`
