import React, { FunctionComponent, useState, useEffect } from 'react'
import styled from 'styled-components'
import cross from '../../../../images/cross.svg'
import theme from '../../../styling/theme'
import Button from '../../Button/Button'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3new'
import Step4 from './Step4'
import DeclineMessage from './DeclineMessage'
import EligibleMessage from './EligibleMessage'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

interface EligibityModalProps {
  showModal: boolean
  toggleModal(state: boolean): void
  type: string
  body: string
}

const EligibityModal: FunctionComponent<EligibityModalProps> = ({
  showModal,
  toggleModal,
}) => {
  const [answer1, setAnswer1] = useState('')
  const [answer2, setAnswer2] = useState('')
  const [answer3, setAnswer3] = useState('')
  const [answer4, setAnswer4] = useState('')

  const decline = <DeclineMessage />
  const [step, setStep] = useState('step1')

  useEffect(() => {
    if (showModal) {
      const scrollable = document.getElementById('eligibility-modal')
      const body = document.querySelector('body')

      disableBodyScroll(scrollable)

      return () => {
        enableBodyScroll(body)
        clearAllBodyScrollLocks()
      }
    }
  }, [showModal])

  const handleNext = () => {
    if (answer4) {
      if (answer4 === 'Yes') {
        return setStep('eligible')
      } else {
        return setStep('decline')
      }
    }
    if (answer3) {
      return setStep('step4')
    }

    if (answer2) {
      if (answer2 === 'Yes') {
        return setStep('step3')
      } else {
        return setStep('decline')
      }
    }
    if (answer1) {
      if (answer1 === 'Yes') {
        return setStep('step2')
      } else {
        return setStep('decline1')
      }
    }
  }

  const handleClose = () => {
    toggleModal(false)
    setStep('step1')
    setAnswer1('')
    setAnswer2('')
    setAnswer3('')
    setAnswer4('')
  }

  const handleBack = () => {
    if (answer4) {
      return setStep('step4')
    }
    if (answer3) {
      return setStep('step3')
    }
    if (answer2) {
      return setStep('step2')
    }
    return setStep('step1')
  }

  const renderStep = (step: string) => {
    switch (step) {
      case 'eligible':
        return <EligibleMessage />
      case 'step4':
        return <Step4 answer={answer4} setAnswer={setAnswer4} />
      case 'step3':
        return <Step3 answer={answer3} setAnswer={setAnswer3} />
      case 'step2':
        return <Step2 answer={answer2} setAnswer={setAnswer2} />
      case 'decline':
        return (
          <DeclineMessage handleBack={handleBack} closeModal={handleClose} />
        )
      case 'decline1':
        return (
          <DeclineMessage
            isFirstStep
            handleBack={handleBack}
            closeModal={handleClose}
          />
        )
      default:
        return <Step1 answer={answer1} setAnswer={setAnswer1} />
    }
  }

  return (
    <OuterContainer
      showModal={showModal}
      onClick={() => toggleModal(false)}
      id="eligibility-modal"
    >
      <Modal onClick={e => e.stopPropagation()}>
        <Top>
          <Inner>
            <p className="p-large">Check your eligibility</p>
            <Cross src={cross} onClick={() => toggleModal(false)} />
            <Closebtn onClick={handleClose}>Close</Closebtn>
          </Inner>
        </Top>
        <Content>
          <Col>
            {step !== 'decline' &&
              step !== 'eligible' &&
              step !== 'decline1' && (
                <h6>
                  Find out now if you qualify for R&D tax credit by answering a
                  few simple questions
                </h6>
              )}
            {renderStep(step)}
            {step !== 'decline' && step !== 'eligible' && step !== 'decline1' && (
              <BtContainer>
                <Button onClick={handleNext}>Next</Button>
              </BtContainer>
            )}
          </Col>
        </Content>
      </Modal>
    </OuterContainer>
  )
}

export default EligibityModal

const Cross = styled.img`
  cursor: pointer;
  width: 20px;

  @media (min-width: 1024px) {
    display: none;
  }
`

const Closebtn = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    width: 90px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${theme.brand.blue};
    background: white;
    color: ${theme.brand.blue};
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: ${theme.brand.blue};
      color: white;
    }
  }
`

interface OuterContainerProps {
  showModal: boolean
}

const OuterContainer = styled.div<OuterContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 999;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 50%;
  }
`

const Top = styled.div`
  background: white;
  border-bottom: 1px solid #ececec;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  padding: 27px 20px;
  @media (min-width: 768px) {
    padding: 22px 3%;
  }
  @media (min-width: 1024px) {
    padding: 22px 5rem;
  }
  @media (min-width: 1200px) {
    padding: 22px 10rem;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  & p {
    color: ${theme.text.main};
    font-weight: bold;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 20px 50px;
  @media (min-width: 768px) {
    padding: 32px 3% 50px;
  }
  @media (min-width: 1024px) {
    padding: 32px 5rem 50px;
  }
  @media (min-width: 1200px) {
    padding: 5.4rem 10rem 10rem;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & h6 {
    font-weight: bold;
    margin-bottom: 30px;
  }
`

const BtContainer = styled.div`
  margin-top: 10rem;
  width: 100%;
  max-width: 200px;
`
