import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

interface ISectionHeading {
  children: any
  center?: boolean
  centerMobile?: boolean
  maxwidth?: boolean
  color?: string
}
const SectionHeading = ({
  children,
  center,
  centerMobile,
  maxwidth,
  color,
}: ISectionHeading) => {
  return (
    <H3
      center={center}
      centerMobile={centerMobile}
      maxwidth={maxwidth}
      color={color}
    >
      {children}
    </H3>
  )
}

export default SectionHeading

const H3 = styled.h3<ISectionHeading>`
  text-align: ${props =>
    props.center || props.centerMobile ? 'center' : 'left'};
  width: 100%;
  max-width: ${props => (props.maxwidth ? props.maxwidth : '920px')};
  margin: ${props => props.center && '0 auto'};
  color: ${props => (props.color ? props.color : theme.text.main)};

  @media (min-width: 768px) {
    text-align: ${props => (props.center ? 'center' : 'left')};
  }
`
