import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import RadioBtn from '../../Form/RadioButton'
import theme from '../../../styling/theme'

interface StepProps {
  setAnswer: (e) => void
  answer: string
}

const Step3: FunctionComponent<StepProps> = ({ setAnswer, answer }) => {
  const [isActive, setActive] = useState(null)
  const [activeDot, setActiveDot] = useState(null)

  return (
    <Container>
      <p className="section-tag">Step 3 of 4</p>
      <Question>
        Please select your industry sector from the list below
      </Question>
      <List>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('IT & Software')
            setAnswer('It and Software')
          }}
          isActive={isActive === 'IT & Software'}
        >
          <Dot isActive={isActive === 'IT & Software'} /> IT & Software
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Construction')
            setAnswer('Construction')
          }}
          isActive={isActive === 'Construction'}
        >
          <Dot isActive={isActive === 'Construction'} /> Construction
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Food production')
            setAnswer('Food production')
          }}
          isActive={isActive === 'Food production'}
        >
          <Dot isActive={isActive === 'Food production'} /> Food production
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Printing')
            setAnswer('Printing')
          }}
          isActive={isActive === 'Printing'}
        >
          <Dot isActive={isActive === 'Printing'} /> Printing
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Manufacturing & engineering')
            setAnswer('Manufacturing & engineering')
          }}
          isActive={isActive === 'Manufacturing & engineering'}
        >
          <Dot isActive={isActive === 'Manufacturing & engineering'} />{' '}
          Manufacturing & engineering
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Quarrying')
            setAnswer('Quarrying')
          }}
          isActive={isActive === 'Quarrying'}
        >
          <Dot isActive={isActive === 'Quarrying'} /> Quarrying
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Security & Communication')
            setAnswer('Security & Communication')
          }}
          isActive={isActive === 'Security & Communication'}
        >
          <Dot isActive={isActive === 'Security & Communication'} /> Security &
          Communication
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Life Sciences')
            setAnswer('Life Sciences')
          }}
          isActive={isActive === 'Life Sciences'}
        >
          <Dot isActive={isActive === 'Life Sciences'} /> Life Sciences
        </Item>
        <Item
          className="p-large radio-btn-step3"
          onClick={() => {
            setActive('Other')
            setAnswer('Other')
          }}
          isActive={isActive === 'Other'}
        >
          <Dot isActive={isActive === 'Other'} /> Other
        </Item>
      </List>
    </Container>
  )
}

export default Step3

const Container = styled.div`
  & .section-tag {
    margin-bottom: 10px;
  }
`

const Question = styled.p`
  color: ${theme.text.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  @media (min-width: 768px) {
    max-width: 375px;
  }
  @media (min-width: 104px) {
    margin-top: 5rem;
  }
`
const List = styled.ul`
  margin-top: 30px;

  & input {
    cursor: pointer;
  }

  & label {
    color: ${theme.text.greyLight};
    margin-left: 9px;
  }
`
interface IDot {
  isActive?: boolean
}
const Item = styled.p<IDot>`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  color: ${props => (props.isActive ? theme.brand.blue : theme.text.greyLight)};
  font-weight: 400px;
  cursor: pointer;
`

const Dot = styled.div<IDot>`
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid
    ${props => (props.isActive ? theme.brand.blue : theme.text.greyLight)};
  margin-right: 9px;
  position: relative;

  &::after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${theme.brand.blue};
    position: absolute;
    top: 1px;
    left: 1px;
    display: ${props => (props.isActive ? '' : 'none')};
  }
`
