import React, { useEffect } from 'react'
import styled from 'styled-components'
import RadioBtn from '../../Form/RadioButton'
import theme from '../../../styling/theme'

interface StepProps {
  setAnswer: (e) => void
  answer: string
}

const Step1 = ({ setAnswer, answer }) => {
  useEffect(() => {
    // console.log(answer)
  }, [answer])
  return (
    <Container>
      <p className="section-tag">Step 1 of 4</p>
      <Question>
        ​Is your company registered for Corporation Tax in the UK?
      </Question>
      <BtnsContainer>
        <RadioBtn
          onClick={() => {
            setAnswer('Yes')
          }}
          isActive={answer === 'Yes'}
        >
          Yes
        </RadioBtn>
        <RadioBtn
          nomarginright
          onClick={() => {
            setAnswer('No')
          }}
          isActive={answer === 'No'}
        >
          No
        </RadioBtn>
      </BtnsContainer>
    </Container>
  )
}

export default Step1

const Container = styled.div`
  & .section-tag {
    margin-bottom: 10px;
  }
`

const Question = styled.p`
  color: ${theme.text.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  @media (min-width: 768px) {
    max-width: 375px;
  }
  @media (min-width: 104px) {
    margin-top: 5rem;
  }
`
