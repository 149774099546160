import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../../../styling/theme'
import CallbackForm from '../../Form/RequestCallBackForm'
import { Container } from './DeclineMessage'

const EligibleMessage = () => {
  return (
    <Container>
      <h4>Great news, you should be eligible!</h4>
      <p>
        There is a good chance that we can assist you in getting you a
        substantial amount of R&D Tax Credits for your innovation.
      </p>
      <p>
        If you would like to speak to one of our R&D experts then please
        complete the the form below alternatively call us on{' '}
        <a href="tel:033 33 444 026">033 33 444 026</a>.
      </p>
      <CallbackForm />
    </Container>
  )
}

export default EligibleMessage
